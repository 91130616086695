import { Modal, Result } from 'antd';

import Button from '../components/elements/Button';
import { BASE_API_URL } from '../config';
import AuthService from './auth';
export interface ApiResponse {
  data: any;
  notifications?: string[];
  pagination?: PaginationModel;
}
export interface ListApiResponse {
  data: any;
  pagination: PaginationModel;
  meta?: any;
}

export interface DeleteApiResponse {
  notifications: string[];
}

export interface ApiError {
  error: {
    code: number;
    details?: string;
    message: string;
  };
}

export interface PaginationModel {
  offset: number;
  limit: number;
  count: number;
}

export const DEFAULT_ERR_MSG =
  'Oops! Something went wrong. Please refresh and try again.';

const isProductionEnvironment = () => {
  const baseApiUrl = BASE_API_URL();
  return baseApiUrl.includes('prod');
};

const errorResult = (err: ApiError) => {
  // Show generic error message for production environment
  let errorMessage = isProductionEnvironment()
    ? DEFAULT_ERR_MSG
    : err.error.message;
  if (
    err.error.code === 111 &&
    err.error.message.includes('Missing resource')
  ) {
    errorMessage = isProductionEnvironment()
      ? DEFAULT_ERR_MSG
      : err.error.message;
  }

  const buttonAction = () => {
    if (
      isProductionEnvironment() ||
      (err.error.code === 111 && err.error.message.includes('Missing resource'))
    ) {
      const ENV = window.location.origin;
      window.location.replace(`${ENV}`);
    } else {
      Modal.destroyAll();
    }
  };
  const buttonLabel =
    isProductionEnvironment() ||
    (err.error.code === 111 && err.error.message.includes('Missing resource'))
      ? 'Back to dashboard'
      : 'I understand';

  return (
    <Result
      status="error"
      title={errorMessage}
      extra={[
        <Button type="primary" onClick={buttonAction} style={{ width: '100%' }}>
          {buttonLabel}
        </Button>,
      ]}
    />
  );
};

export const handleError = (err: ApiError): void => {
  if (err?.error?.message?.includes('Invalid authentication')) {
    AuthService().logout();
    return;
  }

  Modal.error({
    icon: null,
    className: 'global-error-modal',
    maskClosable: true,
    onCancel: () => Modal.destroyAll(),
    centered: true,
    content: errorResult(err),
  });
};

export const postAuth = (url: string, data: any): Promise<ApiResponse> => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  };

  const token = window.localStorage.getItem('token');
  if (token) {
    headers.Authorization = token;
  }

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    })
      .then((resp) => resp.json())
      .then((payload) => {
        if (payload.error) return reject(payload);
        resolve(payload);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const post = (url: string, data: any): Promise<ApiResponse> => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${window.localStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    })
      .then((resp) => resp.json())
      .then((payload) => {
        if (payload.error) return reject(payload);
        resolve(payload);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postFile = (url: string, fileData: FormData): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `${window.localStorage.getItem('token')}`,
      },
      body: fileData,
    })
      .then((resp) => resp.json())
      .then((payload) => {
        if (payload.error) return reject(payload);
        resolve(payload);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const copy = (url: string, data: any): Promise<ApiResponse> => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'COPY',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${window.localStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    })
      .then((resp) => resp.json())
      .then((payload) => {
        if (payload.error) return reject(payload);
        resolve(payload);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const put = (url: string, data: any): Promise<ApiResponse> => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${window.localStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    })
      .then((resp) => resp.json())
      .then((payload) => {
        if (payload.error) return reject(payload);
        resolve(payload);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const patch = (url: string, data: any): Promise<ApiResponse> => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${window.localStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    })
      .then((resp) => resp.json())
      .then((payload) => {
        if (payload.error) return reject(payload);
        resolve(payload);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const get = (url: string): Promise<ApiResponse | ListApiResponse> => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${window.localStorage.getItem('token')}`,
      },
    })
      .then((resp) => resp.json())
      .then((payload) => {
        if (payload.error) return reject(payload);
        resolve(payload);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const del = (url: string): Promise<ApiResponse> => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${window.localStorage.getItem('token')}`,
      },
    })
      .then((resp) => resp.json())
      .then((payload) => {
        if (payload.error) return reject(payload);
        resolve(payload);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
