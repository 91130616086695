import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Form, Input, Select, Space, message } from 'antd';
import { History, Location } from 'history';
import { withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../api/base';
import { ClientModel } from '../../../../models/client';
import { RoleModel } from '../../../../models/role';
import { UserModel, initialNewUserModel } from '../../../../models/user';
import { fetchRoles } from '../../../../store/features/acl/roles/rolesSlice';
import { fetchClients } from '../../../../store/features/clients/clientsSlice';
import {
  createUser,
  disableMfa,
  getCurrentUser,
  getUser,
  updateUser,
} from '../../../../store/features/users/usersSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/index';
import { UserRoles, isUserAuthorized, propsAreEqual } from '../../../../util';
import Button from '../../../elements/Button';
import FormWrapper from '../../../elements/FormWrapper';
import RoleTableList from '../../../views/RolesView/components/RoleTableList/RoleTableList';
import { UserFormLocationState } from './types';

interface UserFormProps {
  history: History;
  location: Location<UserFormLocationState>;
}

const { Option } = Select;

const UserForm = ({ history, location }: UserFormProps) => {
  const [sortedClients, setSortedClients] = useState<ClientModel[]>([]);

  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const { savingUser } = useAppSelector((state) => state.users);
  const { clients, fetchingClients } = useAppSelector((state) => state.clients);
  const { value: roles, fetchingRoles } = useAppSelector(
    (state) => state.roles
  );

  const isNewUser = useMemo(() => !location.state, [location.state]);

  const fields = useMemo(
    () => Object.keys(isNewUser ? initialNewUserModel : location.state?.data),
    [isNewUser, location.state?.data]
  );

  const getInitialUserClientValue = () => {
    return isNewUser ? '' : location.state?.data?.client_id;
  };

  const [selectedRoles, setSelectedRoles] = useState<RoleModel[]>(
    location.state?.data?.roles || []
  );

  const [userClient, setUserClient] = useState<string>(
    getInitialUserClientValue()
  );

  const hasPermission = useMemo((): boolean => {
    return isUserAuthorized([
      UserRoles.admin,
      UserRoles.employeeDeveloper,
      UserRoles.employeeModeler,
      UserRoles.employeeClientServices,
      UserRoles.employeeLibrarian,
    ]);
  }, []);

  const isUserDetailsView = useMemo((): boolean => {
    return location.pathname.includes(`/users/${location?.state?.data?.uuid}`);
  }, [location.pathname, location?.state?.data?.uuid]);

  const onError = useCallback((err: ApiError) => {
    handleError(err);
  }, []);

  const onSuccess = useCallback(async () => {
    message.success('User saved.');

    await dispatch(getCurrentUser());

    if (isUserDetailsView) {
      await dispatch(getUser(location?.state?.data?.uuid));
      history.goBack();
    } else {
      history.goBack();
    }

    setTimeout(() => {
      window.location.reload();
    }, 500);
  }, [dispatch, history, isUserDetailsView, location?.state?.data?.uuid]);

  const saveUser = useCallback(
    async (user: UserModel) => {
      try {
        let userResponse;
        if (isNewUser) {
          userResponse = await dispatch(
            createUser({
              user,
              userRoles: selectedRoles.map(({ name }) => name),
            })
          ).unwrap();
        } else {
          const userId = location.state?.data?.uuid;
          if (!userId) {
            throw new Error('User ID is missing for updating the user');
          }

          userResponse = await dispatch(
            updateUser({
              userId: location.state?.data?.uuid!,
              user,
              userRoles: selectedRoles.map(({ name }) => name),
            })
          ).unwrap();
        }
        if (userResponse) {
          onSuccess();
        }
      } catch (error) {
        onError(error as ApiError);
        console.error('Error:', error);
      }
    },
    [
      dispatch,
      isNewUser,
      location.state?.data?.uuid,
      onError,
      onSuccess,
      selectedRoles,
    ]
  );

  const onSubmit = useCallback(
    (values: UserModel) => {
      form.validateFields(fields).then(() => saveUser(values));
    },
    [fields, form, saveUser]
  );

  const getInitialValues = () => {
    if (isNewUser) {
      return { ...initialNewUserModel };
    }
    return { ...location?.state?.data };
  };

  useEffect(() => {
    if (hasPermission) {
      dispatch(fetchClients({ query: '' }));
    }
  }, [dispatch, hasPermission]);

  useEffect(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  useEffect(() => {
    const sorted = [...clients].sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    setSortedClients(sorted);
  }, [clients]);

  const [mfaPassword, setMfaPassword] = useState<string>('');
  const [showMfaPassword, setShowMfaPassword] = useState<boolean>(false);

  const handleMfaToggle = useCallback(
    async (checked: boolean) => {
      if (!checked && location.state?.data?.email) {
        try {
          await dispatch(
            disableMfa({
              apiUser: location.state.data.email,
              apiPass: mfaPassword,
            })
          ).unwrap();

          if (location.state?.data?.uuid) {
            const updatedUser = await dispatch(
              getUser(location.state.data.uuid)
            ).unwrap();
            history.replace({
              ...location,
              state: { data: updatedUser },
            });
          }

          message.success('MFA disabled successfully');
          setShowMfaPassword(false);
          setMfaPassword('');
        } catch (error) {
          console.error('Error disabling MFA:', error);
        }
      }
    },
    [location, dispatch, mfaPassword, history]
  );

  return (
    <FormWrapper
      title={`${isNewUser ? 'Create new' : 'Edit'} user`}
      onClose={() => history.goBack()}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={getInitialValues()}
        onFinish={onSubmit}
      >
        <Form.Item
          label="User Name"
          name="name"
          rules={[{ required: true, message: 'User name is required.' }]}
        >
          <Input
            data-cy="user-form-input"
            placeholder="User name"
            disabled={!isNewUser}
          />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Please input a valid email address.',
            },
            { required: true, message: 'Email is required.' },
          ]}
        >
          <Input data-cy="user-form-input" placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={
            isNewUser
              ? [
                  {
                    required: true,
                    message: 'Password is required.',
                  },
                ]
              : []
          }
        >
          <Input.Password
            data-cy="user-form-input"
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          label="Client"
          name="client_id"
          rules={
            isNewUser
              ? [
                  {
                    required: true,
                    message: 'Client is required.',
                  },
                ]
              : []
          }
        >
          <Select
            loading={fetchingClients}
            placeholder="Select a Client"
            value={userClient}
            defaultValue={getInitialUserClientValue()}
            onChange={(value) => setUserClient(value)}
          >
            {sortedClients.map((client) => {
              return <Option value={client.uuid}>{client.name}</Option>;
            })}
          </Select>
        </Form.Item>

        <RoleTableList
          roles={roles}
          selectedRoles={selectedRoles}
          loading={fetchingRoles}
          view="Users"
          onRolesSelected={setSelectedRoles}
        />

        {!isNewUser && (
          <>
            <div style={{ margin: '2rem 0' }}>
              <p style={{ marginBottom: 0 }}>MFA Status:</p>
              <p
                style={{
                  fontWeight: 'bold',
                  margin: 0,
                  color:
                    location.state?.data?.mfa_status === 'enabled'
                      ? '#52c41a'
                      : '#ff4d4f',
                }}
              >
                {location.state?.data?.mfa_status.toUpperCase()}
              </p>
            </div>

            {location.state?.data?.mfa_status === 'enabled' && (
              <>
                {!showMfaPassword ? (
                  <Form.Item>
                    <Button onClick={() => setShowMfaPassword(true)}>
                      Disable MFA
                    </Button>
                  </Form.Item>
                ) : (
                  <Form.Item label="Password to Disable MFA" required>
                    <Space>
                      <Input.Password
                        value={mfaPassword}
                        onChange={(e) => setMfaPassword(e.target.value)}
                        placeholder="Enter password to disable MFA"
                      />
                      <Button
                        onClick={() => handleMfaToggle(false)}
                        disabled={!mfaPassword}
                      >
                        Confirm
                      </Button>
                      <Button
                        onClick={() => {
                          setShowMfaPassword(false);
                          setMfaPassword('');
                        }}
                      >
                        Cancel
                      </Button>
                    </Space>
                  </Form.Item>
                )}
              </>
            )}
          </>
        )}

        <Form.Item>
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            <Button
              loading={savingUser as boolean}
              data-cy="user-form-submit-btn"
              htmlType="submit"
              type="primary"
              style={{ width: '100%' }}
            >
              {`${isNewUser ? 'Create' : 'Save'} User`}
            </Button>
            <Button onClick={() => history.goBack()} style={{ width: '100%' }}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default withRouter<any, any>(memo(UserForm, propsAreEqual));
