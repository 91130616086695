import { memo, useCallback, useContext, useEffect, useMemo } from 'react';

import {
  BarChartOutlined,
  BuildOutlined,
  CloseOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  FileTextOutlined,
  HomeOutlined,
  PicCenterOutlined,
  SettingOutlined,
  StarOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Tooltip } from 'antd';
import cx from 'classnames';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import './Sidebar.less';

import { ReactComponent as CylinderIcon } from '../../../assets/svgs/cylinder-icon.svg';
import ReadySetIcon from '../../../assets/svgs/readyset-icon-white.svg';
import ReadySetLogo from '../../../assets/svgs/readyset-logo-white.svg';
import { ReactComponent as RolesIcon } from '../../../assets/svgs/roles-icon.svg';
import { useAppDispatch, useAppSelector } from '../../../store';
// import { getClient } from '../../../store/features/clients/clientsSlice';
import { useMfaCheck } from '../../../hooks/useMfaCheck';
import {
  UserPermissions,
  UserRoles,
  getMe,
  getMePermissions,
  isTabletScreenSizeOrSmaller,
  isUserAuthorized,
} from '../../../util';
import { AppLayoutContext } from '../../views/AppRoot/AppRoot';
import { RoutePath } from '../../views/AppRoot/types';
import SelectClientDropdown from './components/SelectClientDropdown';
import UserProfileDropdown from './components/UserProfileDropdown';

const { Sider } = Layout;
const { SubMenu } = Menu;

interface SidebarProps extends RouteComponentProps {
  onToggle: () => void;
}

const Sidebar = ({ location, onToggle }: SidebarProps) => {
  const { sidebarCollapsed } = useContext(AppLayoutContext);
  const currentUserPermissions = useMemo(
    (): string[] => getMePermissions(),
    []
  );
  const dispatch = useAppDispatch();

  const { myClient } = useAppSelector((state) => state.clients);

  useMfaCheck();

  useEffect(() => {
    const fetchClient = () => {
      try {
        const me = getMe();
        if (me.client_id) {
          // dispatch(getClient({ id: me.client_id, me: true }));
        }
      } catch (error) {
        console.error('Error fetching current client:', error);
      }
    };
    fetchClient();
  }, [dispatch]);

  const hasPermission = useMemo((): boolean => {
    return isUserAuthorized([
      UserRoles.admin,
      UserRoles.employeeDeveloper,
      UserRoles.employeeModeler,
      UserRoles.employeeClientServices,
      UserRoles.employeeLibrarian,
    ]);
  }, []);

  const isTablet = useMemo(() => isTabletScreenSizeOrSmaller(), []);

  const getKey = useCallback(
    (pathname: string): string => {
      if (location.pathname.includes(pathname)) {
        return location.pathname;
      }
      return pathname;
    },
    [location.pathname]
  );

  const sidebarClass = cx('sidebar-container', {
    hidden: isTablet && sidebarCollapsed,
  });

  return (
    <div
      className={`sidebar-background ${sidebarCollapsed ? 'closed' : 'open'}`}
    >
      <Sider
        breakpoint="lg"
        collapsedWidth={80}
        width={isTablet ? '100%' : sidebarCollapsed ? 80 : 250}
        trigger={
          <>
            <div
              className="sidebar-footer"
              onClick={(e) => e.stopPropagation()}
            >
              <UserProfileDropdown collapsed={sidebarCollapsed!} />
            </div>
            {sidebarCollapsed ? (
              <div style={{ height: '46px', padding: '4px' }}>
                <Tooltip placement="right" title="Expand sidebar">
                  <DoubleRightOutlined />
                </Tooltip>
              </div>
            ) : (
              <div className="collapse-action-wrapper">
                <div className="collapse-icon-wrapper">
                  <DoubleLeftOutlined />
                </div>
                <div>Collapse sidebar</div>
              </div>
            )}
          </>
        }
        collapsible
        collapsed={sidebarCollapsed}
        className={sidebarClass}
        onCollapse={onToggle}
      >
        <header className="sidebar-header">
          <Link to={RoutePath.Dashboard}>
            <img
              src={sidebarCollapsed ? ReadySetIcon : ReadySetLogo}
              alt="ReadySet logo"
              className="logo"
            />
          </Link>
          {isTablet && (
            <CloseOutlined onClick={onToggle} className="sidebar-icon large" />
          )}
        </header>

        {currentUserPermissions.includes(UserPermissions.UserSwitchClients) ? (
          <SelectClientDropdown collapsed={sidebarCollapsed!} />
        ) : null}

        <Menu
          mode="inline"
          selectedKeys={[location.pathname]}
          onClick={isTablet ? onToggle : undefined}
          inlineCollapsed={sidebarCollapsed}
        >
          <Menu.Item
            key={getKey(RoutePath.Dashboard)}
            icon={<HomeOutlined className="sidebar-icon" />}
          >
            <Link to={RoutePath.Dashboard}>Home</Link>
          </Menu.Item>
          {hasPermission ? (
            <SubMenu
              key={getKey(RoutePath.Products)}
              icon={
                <span className="anticon">
                  <CylinderIcon className="custom-icon" />
                </span>
              }
              title="Products"
            >
              <Menu.Item key={getKey(RoutePath.ProductLibrary)}>
                <Link to={RoutePath.ProductLibrary}>Product Library</Link>
              </Menu.Item>

              <Menu.Item key={getKey(RoutePath.ProductList)}>
                <Link to={RoutePath.ProductList}>Product List</Link>
              </Menu.Item>

              {hasPermission ? (
                <Menu.Item key={getKey(RoutePath.MasterLibrarianList)}>
                  <Link to={RoutePath.MasterLibrarianList}>
                    Master Librarian List
                  </Link>
                </Menu.Item>
              ) : null}

              {isUserAuthorized([
                UserRoles.admin,
                UserRoles.employeeModeler,
                UserRoles.employeeLibrarian,
              ]) ? (
                <Menu.Item key={getKey(RoutePath.UploadModels)}>
                  <Link to={RoutePath.UploadModels}>Upload Models</Link>
                </Menu.Item>
              ) : null}

              <Menu.Item key={getKey(RoutePath.POGChecker)}>
                <Link to={RoutePath.POGChecker}>POG Checker</Link>
              </Menu.Item>

              {hasPermission ? (
                <Menu.Item key={getKey(RoutePath.MetaData)}>
                  <Link to={RoutePath.MetaData}>Meta Data</Link>
                </Menu.Item>
              ) : null}

              {/* {hasPermission && (
              <Menu.Item key={getKey(RoutePath.ReplacementData)}>
                <Link to={RoutePath.ReplacementData}>Replacement Data</Link>
              </Menu.Item>
            )} */}

              {hasPermission && (
                <Menu.Item key={getKey(RoutePath.Clients)}>
                  <Link to={RoutePath.Clients}>Clients</Link>
                </Menu.Item>
              )}

              {currentUserPermissions.includes(
                UserPermissions.ModelViewLibraryStatistics
              ) ? (
                <Menu.Item key={getKey(RoutePath.Status)}>
                  <Link to={RoutePath.Status}>Status</Link>
                </Menu.Item>
              ) : null}
            </SubMenu>
          ) : null}

          <SubMenu
            key={getKey(RoutePath.OVR)}
            icon={<BarChartOutlined />}
            title="Online Virtual Research"
          >
            <Menu.Item key={getKey(RoutePath.OvrFileManagement)}>
              <Link to={RoutePath.OvrFileManagement}>Cells</Link>
            </Menu.Item>
            <Menu.Item key={getKey(RoutePath.OVR)}>
              <Link to={RoutePath.OVR}>Projects</Link>
            </Menu.Item>
          </SubMenu>

          <Menu.Item
            data-cy="store-builder-sidebar-menu-item"
            key={getKey(RoutePath.StoreBuilder)}
            icon={<BuildOutlined className="sidebar-icon" />}
          >
            <Link to={RoutePath.StoreBuilder}>Front End Builder</Link>
          </Menu.Item>

          {myClient?.name ? (
            <Menu.Item
              data-cy="users-sidebar-menu-item"
              key={getKey(RoutePath.Client)}
              icon={<StarOutlined className="sidebar-icon" />}
            >
              <Link to={`/clients/${myClient.uuid}`}>{myClient?.name}</Link>
            </Menu.Item>
          ) : null}

          <Menu.Item
            data-cy="manual-sidebar-menu-item"
            key={getKey(RoutePath.Manual)}
            icon={<FileTextOutlined className="sidebar-icon" />}
          >
            <Link to={RoutePath.Manual}>Manual / Videos</Link>
          </Menu.Item>

          {hasPermission ? (
            <>
              <Menu.Item
                data-cy="users-sidebar-menu-item"
                key={getKey(RoutePath.Users)}
                icon={<TeamOutlined className="sidebar-icon" />}
              >
                <Link to={RoutePath.Users}>Users</Link>
              </Menu.Item>
              {isUserAuthorized([UserRoles.admin]) ? (
                <Menu.Item
                  data-cy="roles-sidebar-menu-item"
                  key={getKey(RoutePath.Roles)}
                  icon={
                    <span role="img" className="anticon sidebar-icon">
                      <RolesIcon width={25} height={25} />
                    </span>
                  }
                >
                  <Link to={RoutePath.Roles}>Roles</Link>
                </Menu.Item>
              ) : null}
              <Menu.Item
                data-cy="custom-fields-menu-item"
                key={getKey(RoutePath.CustomFields)}
                icon={<PicCenterOutlined className="sidebar-icon" />}
              >
                <Link to={RoutePath.CustomFields}>Custom Fields</Link>
              </Menu.Item>
              <Menu.Item
                data-cy="system-configurations-sidebar-menu-item"
                key={getKey(RoutePath.SystemConfigurations)}
                icon={<SettingOutlined className="sidebar-icon" />}
              >
                <Link to={RoutePath.SystemConfigurations}>
                  System Configurations
                </Link>
              </Menu.Item>
            </>
          ) : null}
        </Menu>
      </Sider>
    </div>
  );
};

export default withRouter(memo(Sidebar));
