import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Card, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import './LogInView.less';

import AuthService, { AuthenticateRequest } from '../../../api/auth';
import { ApiError, handleError } from '../../../api/base';
import ClientService from '../../../api/client';
import ReadySetLogo from '../../../assets/svgs/readyset-logo.svg';
import Button from '../../elements/Button';
import { RoutePath } from '../AppRoot/types';

const LogInView = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [currentAuthStep, setCurrentAuthStep] = useState<number>(0);
  const [qrCode, setQrCode] = useState<string>('');
  const [mfaCode, setMfaCode] = useState<string>('');
  const [userMFAStatus, setUserMFAStatus] = useState<string>('');
  const [form] = Form.useForm();
  const [authCredentials, setAuthCredentials] =
    useState<AuthenticateRequest | null>(null);

  const onSuccess = () => {
    history.push('/');
  };

  const onError = (err: ApiError) => {
    handleError(err);
  };

  const onFinally = () => {
    setLoading(false);
  };

  const onSubmit = async (userCredentials: AuthenticateRequest) => {
    setLoading(true);
    try {
      setAuthCredentials(userCredentials);
      const authenticateResp = await AuthService().authenticate(
        userCredentials
      );
      const userData = authenticateResp.data.user;
      setUserMFAStatus(userData.mfa_status);

      const clientResp = await ClientService().getClientMFAStatus(
        userData.client_id
      );
      const clientMFAStatus = clientResp.data.mfa_status;
      localStorage.setItem('client_mfa_status', clientMFAStatus);

      if (userData.mfa_status === 'disabled') {
        if (clientMFAStatus === 'required') {
          const mfaResp = await AuthService().requestMFA(userCredentials);
          setQrCode(mfaResp.qrCode);
          setCurrentAuthStep(1);
        } else if (clientMFAStatus === 'optional') {
          setCurrentAuthStep(4);
        } else {
          onSuccess();
        }
      } else if (userData.mfa_status === 'enabled') {
        setCurrentAuthStep(3);
      } else {
        onSuccess();
      }
    } catch (err: any) {
      if (err.error?.code === 'E_MFA_TOKEN_REQUIRED') {
        setUserMFAStatus('enabled');
        setCurrentAuthStep(3);
      } else {
        onError(err);
      }
    } finally {
      onFinally();
    }
  };

  const onContinueWithout2FA = async () => {
    setLoading(true);
    try {
      if (!authCredentials) {
        console.error('No authentication credentials found');
        return;
      }
      const authResp = await AuthService().authenticate(authCredentials);
      if (!authResp.data) {
        throw new Error('Authentication failed');
      }

      const meResp = await AuthService().getMe();
      if (!meResp.data) {
        throw new Error('Failed to fetch user data');
      }

      AuthService().setMe(meResp.data as any);
      onSuccess();
    } catch (err: any) {
      onError(err);
    } finally {
      onFinally();
    }
  };

  const onConfirmMFA = async () => {
    if (!authCredentials) {
      console.error('No authentication credentials found');
      return;
    }

    try {
      setLoading(true);

      if (userMFAStatus === 'disabled') {
        const confirmResp = await AuthService().confirmMFA({
          mfaToken: mfaCode,
        });

        if (confirmResp.data === 'MFA has been confirmed.') {
          const authResp = await AuthService().authenticate({
            ...authCredentials,
            mfaToken: mfaCode,
          });

          if (authResp.data) {
            AuthService().setMe(authResp.data as any);
            onSuccess();
          }
        }
      } else if (userMFAStatus === 'enabled') {
        const authResp = await AuthService().authenticate({
          ...authCredentials,
          mfaToken: mfaCode,
        });

        if (authResp.data) {
          console.log('setting me');
          AuthService().setMe(authResp.data as any);
          onSuccess();
        }
      }
    } catch (err: any) {
      onError(err);
    } finally {
      onFinally();
    }
  };

  const authSteps = [
    {
      content: (
        <>
          <Form
            layout="vertical"
            className="log-in-form"
            onFinish={onSubmit}
            requiredMark={false}
            form={form}
          >
            <Form.Item
              label="Email"
              name="apiUser"
              rules={[{ required: true, message: 'Email is required.' }]}
            >
              <Input
                autoFocus
                data-cy="log-in-email-input"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="apiPass"
              rules={[{ required: true, message: 'Password is required.' }]}
              className="log-in-password-input"
            >
              <Input.Password
                data-cy="log-in-password-input"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <Link
              to={RoutePath.ForgotPassword}
              className="log-in-forgotten-label"
            >
              Forgot password?
            </Link>

            <Form.Item>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                className="log-in-form-button"
                data-cy="log-in-btn"
              >
                Log In
              </Button>
            </Form.Item>
          </Form>
        </>
      ),
    },
    {
      content: (
        <>
          <h3 className="log-in-step-header">
            Two-Factor Authentication Required
          </h3>
          <p style={{ marginTop: 12 }}>
            To add an extra layer of security to your account, we recommend
            setting up Time-Based One-Time Password (TOTP) authentication. This
            requires a code generated by an app on your phone in addition to
            your password when you log in.
          </p>
          <div style={{ marginTop: 24 }}>
            <h4>1. Download a TOTP Authenticator App</h4>
            <ul>
              <li>Google Authenticator (iOS and Android)</li>
              <li>Microsoft Authenticator (iOS and Android)</li>
              <li>FreeOTP (iOS and Android)</li>
            </ul>
          </div>
          <Button
            type="primary"
            onClick={() => setCurrentAuthStep(2)}
            style={{ marginTop: 24 }}
          >
            Continue
          </Button>
        </>
      ),
    },
    {
      content: (
        <>
          <h3 className="log-in-step-header">Scan QR Code</h3>
          <p style={{ marginTop: 12 }}>
            Open your authenticator app, select the option to scan a QR code,
            and use your camera to scan the displayed code.
          </p>
          {qrCode && (
            <div style={{ margin: '24px 0' }}>
              <img
                src={qrCode}
                alt="MFA QR Code"
                style={{ height: '200px', width: '100%' }}
              />
            </div>
          )}
          <p>
            After scanning, your app will generate a six-digit code. Enter this
            code to verify and complete the setup.
          </p>
          <div
            className="forgot-password-input-wrapper"
            style={{ marginBottom: 16 }}
          >
            <label htmlFor="mfa-code" className="forgot-password-input-label">
              Verification Code
            </label>
            <Input
              autoFocus
              name="mfa-code"
              placeholder="Enter 6-digit code"
              value={mfaCode}
              onChange={(e) => setMfaCode(e.target.value)}
              maxLength={6}
            />
          </div>
          <p>
            <strong>
              Important: Remember, you'll need this app to access your account
              in the future. If you change devices, be sure to transfer your
              authentication codes to your new phone.
            </strong>
          </p>
          <Button
            loading={loading}
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              onConfirmMFA();
            }}
          >
            Verify and Complete Setup
          </Button>
        </>
      ),
    },
    {
      content: (
        <>
          <h3 className="log-in-step-header">Two-Factor Authentication</h3>
          <p style={{ marginTop: 12 }}>
            Please enter the verification code from your authenticator app to
            continue.
          </p>
          <div
            className="forgot-password-input-wrapper"
            style={{ marginBottom: 16 }}
          >
            <label htmlFor="mfa-code" className="forgot-password-input-label">
              Verification Code
            </label>
            <Input
              autoFocus
              name="mfa-code"
              placeholder="Enter 6-digit code"
              value={mfaCode}
              onChange={(e) => setMfaCode(e.target.value)}
              maxLength={6}
            />
          </div>
          <Button
            loading={loading}
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              onConfirmMFA();
            }}
          >
            Verify
          </Button>
        </>
      ),
    },
    {
      content: (
        <>
          <h3 className="log-in-step-header">
            Two-Factor Authentication Available
          </h3>
          <p style={{ marginTop: 12 }}>
            Would you like to set up Two-Factor Authentication for additional
            account security? This will require a code generated by an app on
            your phone in addition to your password when you log in.
          </p>
          <div className="mfa-choice-buttons">
            <Button
              type="primary"
              onClick={async () => {
                if (!authCredentials) return;
                const mfaResp = await AuthService().requestMFA(authCredentials);
                setQrCode(mfaResp.qrCode);
                setCurrentAuthStep(1);
              }}
            >
              Set Up 2FA
            </Button>
            <Button type="default" onClick={onContinueWithout2FA}>
              Continue Without 2FA
            </Button>
          </div>
        </>
      ),
    },
  ];

  return (
    <div className="log-in-view">
      <Card style={{ width: '450px', marginBottom: '72px' }}>
        <div className="log-in-card-content-wrapper">
          <img
            src={ReadySetLogo}
            alt="ReadySet logo"
            className="readyset-logo"
          />
          {authSteps[currentAuthStep].content}
        </div>
      </Card>
    </div>
  );
};

export default LogInView;
