import { RoleModel } from '../role';

export interface UserModel {
  client_id: string;
  created_at: string;
  email: string;
  email_verified_at: string;
  name: string;
  updated_at: string;
  uuid: string;
  password: string;
  roles: RoleModel[];
  mfa_status: 'enabled' | 'disabled';
  permissions?: { key: string }[];
  token?: string;
}

export const initialNewUserModel = {
  name: '',
  email: '',
  password: '',
};
