import { BASE_API_URL } from '../../config';
import { UserModel } from '../../models/user';
import { get, post, postAuth } from '../base';
import {
  AuthenticateRequest,
  AuthenticateResponse,
  ConfirmMFAResponse,
  MeResponse,
  MFAEnabledResponse,
  RequestMFAResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  Send2FACodeResponse,
  SendPasswordResetVerificationCodeResponse,
  Verify2FACodeRequest,
  VerifyResetCodeRequest,
  VerifyResetCodeResponse,
} from './reqres';

const onAuthSuccess = (resp: AuthenticateResponse): Promise<MeResponse> => {
  AuthService().setToken(resp.data.token);
  return AuthService().getMe();
};

const onMeSuccess = (resp: MeResponse): Promise<MeResponse> => {
  AuthService().setMe(resp.data.user);
  return Promise.resolve(resp);
};

export default function AuthService() {
  return Object.freeze({
    async authenticate(
      req: AuthenticateRequest
    ): Promise<MeResponse | MFAEnabledResponse> {
      try {
        const authResp = await postAuth(`${BASE_API_URL()}/auth/login`, {
          apiUser: req.apiUser,
          apiPass: req.apiPass,
          mfaToken: req.mfaToken,
        });

        AuthService().setToken(authResp.data.token);

        if (authResp.data.user.mfa_status === 'disabled') {
          return authResp;
        }

        try {
          const meResp = await this.getMe();
          AuthService().setMe(meResp.data.user);
          return meResp;
        } catch (meError) {
          console.error('Failed to get user data:', meError);
          throw meError;
        }
      } catch (err: any) {
        console.log('Caught error in auth service:', err);
        throw err;
      }
    },
    send2FACode(email: string): Promise<Send2FACodeResponse> {
      return post(`${BASE_API_URL()}/send2FACode`, { email });
    },
    verify2FACode(body: Verify2FACodeRequest): Promise<MeResponse> {
      return post(`${BASE_API_URL()}/verify2FACode`, body)
        .then(onAuthSuccess)
        .then(onMeSuccess);
    },
    getMe(): Promise<MeResponse> {
      return get(`${BASE_API_URL()}/user/me?_columns=permissions.*,roles.*`);
    },
    setToken(token: string): void {
      localStorage.setItem('token', token);
    },
    setMe(me: UserModel): void {
      localStorage.setItem('me', JSON.stringify(me));
    },
    logout(): void {
      localStorage.clear();
      window.location.replace(window.location.origin);
    },
    isAuthenticated(): boolean {
      try {
        const token = localStorage.getItem('token');
        const me = localStorage.getItem('me');
        if (!token || !me) return false;
        const userData = JSON.parse(me);

        const clientMfaStatus = localStorage.getItem('client_mfa_status');
        if (
          clientMfaStatus === 'required' &&
          userData.mfa_status === 'disabled'
        ) {
          this.logout();
          return false;
        }

        return !!userData?.permissions;
      } catch (e) {
        console.error('Error checking authentication:', e);
        return false;
      }
    },
    sendPasswordResetVerificationCode(
      email: string
    ): Promise<SendPasswordResetVerificationCodeResponse> {
      return post(`${BASE_API_URL()}/sendResetPasswordCode`, { email });
    },
    verifyResetCode(
      body: VerifyResetCodeRequest
    ): Promise<VerifyResetCodeResponse> {
      return post(`${BASE_API_URL()}/verifyResetCode`, body);
    },
    resetPassword(body: ResetPasswordRequest): Promise<ResetPasswordResponse> {
      return post(`${BASE_API_URL()}/resetPassword`, body);
    },
    async requestMFA(
      credentials: AuthenticateRequest
    ): Promise<RequestMFAResponse> {
      return postAuth(`${BASE_API_URL()}/user/requestMfa`, credentials) as any;
    },
    confirmMFA(body: { mfaToken: string }): Promise<ConfirmMFAResponse> {
      return post(`${BASE_API_URL()}/user/confirmMfa`, {
        mfaToken: body.mfaToken,
      }) as any;
    },
  });
}
