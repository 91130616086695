import { BASE_API_URL } from '../../config';
import { ApiResponse, del, get, ListApiResponse, patch, post } from '../base';
import { PermissionsResponse } from '../permission';
import {
  CreateUserRequest,
  UpdateUserClientRequest,
  UpdateUserPermissionsRequest,
  UpdateUserRequest,
  UserResponse,
  UsersResponse,
} from './reqres';

export default function UserService() {
  return Object.freeze({
    createUser(req: CreateUserRequest): Promise<UserResponse> {
      return post(`${BASE_API_URL()}/user`, req);
    },
    updateUser(
      userId: string,
      req: UpdateUserRequest,
      query = ''
    ): Promise<UserResponse> {
      return patch(`${BASE_API_URL()}/user/${userId}${query}`, req);
    },
    updateUserClient(
      userId: string,
      req: UpdateUserClientRequest
    ): Promise<UserResponse> {
      return patch(`${BASE_API_URL()}/user/${userId}`, req);
    },
    listUsers(query?: string): Promise<UsersResponse> {
      return get(`${BASE_API_URL()}/user${query}`) as Promise<ListApiResponse>;
    },
    getCurrentUser(): Promise<UserResponse> {
      return get(`${BASE_API_URL()}/user/me`);
    },
    getUser(userId: string, query?: string): Promise<UserResponse> {
      return get(`${BASE_API_URL()}/user/${userId}${query}`);
    },
    deleteUser(userId: string): Promise<ApiResponse> {
      return del(`${BASE_API_URL()}/user/${userId}`);
    },
    updateUserPermissions(
      userId: string,
      req: UpdateUserPermissionsRequest
    ): Promise<PermissionsResponse> {
      return post(
        `${BASE_API_URL()}/user/${userId}/permission`,
        req
      ) as Promise<ListApiResponse>;
    },
    disableMfa(apiUser: string, apiPass: string): Promise<ApiResponse> {
      return post(`${BASE_API_URL()}/user/disableMfa`, { apiUser, apiPass });
    },
  });
}
